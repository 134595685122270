<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" lg="4">
        <totalPriceCard
          :schoolId="tagId"
          :propsMeters="meters"
          :loadingMeter="loadingMeter"
        />
        <totalEnergyCard
          class="mt-4"
          :schoolId="tagId"
          :propsMeters="meters"
          :loadingMeter="loadingMeter"
        />
        <weatherCard :schoolId="tagId" class="mt-4" />
      </v-col>
      <v-col cols="12" lg="8">
        <meterTab
          :schoolId="tagId"
          :meters="meters"
          :loadingMeter="loadingMeter"
        />
      </v-col>
      <v-col cols="12">
        <classList :tagId="tagId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import weatherCard from './weatherCard'
import meterTab from './meterTab'
import classList from './classList'
import totalPriceCard from './totalPriceCard'
import totalEnergyCard from './totalEnergyCard'
import Meter from '@/api/ems/Meter'
import { countdownReload } from '@/shared/utils'

export default {
  name: undefined,
  mixins: [],
  components: {
    weatherCard,
    meterTab,
    classList,
    totalPriceCard,
    totalEnergyCard
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      timer: null,
      meters: [],
      loadingMeter: false
    }
  },
  computed: {},
  watch: {
    tagId(newTagId) {
      this.getSchoolMeters(newTagId)
    }
  },
  created: function () {},
  mounted: function () {
    this.getSchoolMeters()

    // 每分鐘更新一次
    this.timer = countdownReload(this.getSchoolMeters, 60)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    async getSchoolMeters() {
      this.loadingMeter = true
      this.meters = []
      try {
        const { data } = await Meter.getSchoolMeters(this.tagId)
        this.meters = data
        if (this.meters.length) this.getMetersDetail()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingMeter = false
      }
    },
    getMetersDetail() {
      this.meters.forEach(async (meter, index) => {
        const { data } = await Meter.getMeterDetail(meter.id)
        this.meters.splice(index, 1, { ...this.meters[index], ...data })
      })
    }
  }
}
</script>

<style></style>
