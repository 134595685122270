<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" lg="3" v-for="item in classes" :key="item.id">
        <classCard :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Meter from '@/api/ems/Meter'
import classCard from './classCard'

export default {
  name: undefined,
  mixins: [],
  components: {
    classCard
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      classes: []
    }
  },
  computed: {},
  watch: {
    tagId: {
      handler: function () {
        this.getAllClassMeters()
      },
      immediate: true
    }
  },
  created: function () {},
  mounted: function () {
    this.getAllClassMeters()
  },
  destroyed() {},
  methods: {
    async getAllClassMeters() {
      this.loadingClass = true
      try {
        const { data } = await Meter.getAllClassMeters(this.tagId)
        this.classes = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingClass = false
      }
    }
  }
}
</script>

<style></style>
